import { Injectable } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { Router } from "@angular/router";
import { of } from "rxjs";
import { delay } from "rxjs/operators";
import { AngularFireAuth } from "@angular/fire/auth";
import { auth } from "firebase/app";
import  * as firebase from 'firebase/app';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: "root"
})
export class AuthService {
  //Only for demo purpose
  authenticated = true;

  constructor(
    private store: LocalStoreService, 
    private router: Router,
    private http: HttpClient,
    private afAuth: AngularFireAuth) {
    this.checkAuth();
  }

  checkAuth() {
    // this.authenticated = this.store.getItem("demo_login_status");
  }

  getuser() {
    return of({});
  }
   
  singup(email, pass){
    return this.afAuth.createUserWithEmailAndPassword(email, pass)
  }
  
  async singWgoogle(){
    const provider = new auth.GoogleAuthProvider();
    const credential = await this.afAuth.signInWithPopup(provider);
    return this.adminLogin(credential.user);
  }

  async signinEmPs(em, ps){
    const provider = new auth.GoogleAuthProvider();
    const credential = await this.afAuth.signInWithEmailAndPassword(em,ps);
    return this.adminLogin(credential.user);
  }

  adminLogin(user){
   const cuid = user.uid;
   const em = user.email;
   if(cuid){
     this.authenticated = true;
      this.store.setItem('cemail', em);
      this.store.setItem('cuid', cuid);
     this.router.navigateByUrl('/dashboard/v1');
   }
  }

  signin(credentials) {
    this.authenticated = true;
    this.store.setItem("demo_login_status", true);
    return of({}).pipe(delay(1500));
  }

  sendnotifOns(pdata){
    const httpOption = {
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'Authorization':'Basic MDJhMTZhY2ItYjVkNy00OGY1LTkyOTgtMDliZGJlNWI5YzBi'
      })
    };
   return this.http.post('https://onesignal.com/api/v1/notifications',pdata,httpOption);
  }

  

  signout() {
    this.afAuth.signOut();
    this.authenticated = false;
    this.store.setItem("demo_login_status", false);
    this.router.navigateByUrl("/sessions/signin");
  }

  
}
