export const environment = {
  production: true,
  firebaseConfig : {
    apiKey: "AIzaSyDctap7xf2JisiysdbK9pgGbZ6a40Z2x3Y",
  authDomain: "baked-86fe6.firebaseapp.com",
  databaseURL: "https://baked-86fe6-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "baked-86fe6",
  storageBucket: "baked-86fe6.appspot.com",
  messagingSenderId: "295158950761",
  appId: "1:295158950761:web:9e3ca6feb886e44ffd6bf8",
  measurementId: "G-G2W0MQXQYN"
  }
};
