import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface IMenuItem {
    id?: string;
    title?: string;
    description?: string;
    type: string;       // Possible values: link/dropDown/extLink
    name?: string;      // Used as display text for item and title for separator type
    state?: string;     // Router state
    icon?: string;      // Material icon name
    tooltip?: string;   // Tooltip text
    disabled?: boolean; // If true, item will not be appeared in sidenav.
    sub?: IChildItem[]; // Dropdown items
    badges?: IBadge[];
    active?: boolean;
}
export interface IChildItem {
    id?: string;
    parentId?: string;
    type?: string;
    name: string;       // Display text
    state?: string;     // Router state
    icon?: string;
    sub?: IChildItem[];
    active?: boolean;
}

interface IBadge {
    color: string;      // primary/accent/warn/hex color codes(#fff000)
    value: string;      // Display text
}

interface ISidebarState {
    sidenavOpen?: boolean;
    childnavOpen?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    public sidebarState: ISidebarState = {
        sidenavOpen: true,
        childnavOpen: false
    };
    selectedItem: IMenuItem;
    
    constructor() {
    }

    defaultMenu: IMenuItem[] = [
        {   
            name: 'Dashboard',
            description: 'Data keseluruhan Resto yang tergabung dalam membership',
            type: 'dropDown',
            icon: 'i-Bar-Chart',
            sub: [
                { icon: 'i-Business-Mens', name: 'Guest', state: '/dashboard/guest', type: 'link' },
                { icon: 'i-Business-Mens', name: 'Member', state: '/dashboard/v1', type: 'link' },
                // { icon: 'i-Tag', name: 'Level Member', state: '/dashboard/level', type: 'link' },
                { icon: 'i-Newspaper', name: 'Promo', state: '/dashboard/v2', type: 'link' },
                { icon: 'i-Newspaper', name: 'Voucher', state: '/dashboard/voclist', type: 'link' },
                { icon: 'i-ID-2', name: 'Template', state: '/dashboard/v3', type: 'link' },
                { icon: 'i-ID-2', name: 'Send Promo', state: '/dashboard/sendpromo', type: 'link' },
                { icon: 'i-ID-2', name: 'Notif Multiple', state: '/dashboard/notif', type: 'link' },
                // { icon: 'i-Reload', name: 'Reset Poin', state: '/dashboard/respoin', type: 'link' },
            ]
        },
       
    ];


    // sets iconMenu as default;
    menuItems = new BehaviorSubject<IMenuItem[]>(this.defaultMenu);
    // navigation component has subscribed to this Observable
    menuItems$ = this.menuItems.asObservable();

    // You can customize this method to supply different menu for
    // different user type.
    // publishNavigationChange(menuType: string) {
    //   switch (userType) {
    //     case 'admin':
    //       this.menuItems.next(this.adminMenu);
    //       break;
    //     case 'user':
    //       this.menuItems.next(this.userMenu);
    //       break;
    //     default:
    //       this.menuItems.next(this.defaultMenu);
    //   }
    // }
}
