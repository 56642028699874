<div class="main-header">
        <div class="logo">
            <img src="./assets/images/logo.png" alt="">
        </div>
    
        <div class="menu-toggle" (click)="toggelSidebar()">
            <div></div>
            <div></div>
            <div></div>
        </div>
    
        <div class="d-flex align-items-center">
            <!-- Mega menu -->
            <!-- <div ngbDropdown class="mega-menu d-none d-md-block">
                <button ngbDropdownToggle href="#" class="btn text-muted dropdown-toggle mr-3">Mega Menu</button>
            </div> -->
            <!-- Search bar -->
            <!-- <div class="search-bar">
                <input type="text" placeholder="Search" (focus)="searchService.searchOpen = true">
                <i class="search-icon text-muted i-Magnifi-Glass1"></i>
            </div> -->
        </div>
    
        <div style="margin: auto"></div>
    
        <div class="header-part-right">
            <!-- <i class="i-Magnifi-Glass1 header-icon" role="button" (click)="searchService.searchOpen = true"></i>
            <i fullScreenWindow class="i-Full-Screen header-icon d-none d-sm-inline-block" role="button"></i> -->
            <!-- <div ngbDropdown [placement]="'bottom-right'" class="d-none d-sm-inline-block">
                <i ngbDropdownToggle class="i-Gear text-muted header-icon" role="button"></i>
                <div ngbDropdownMenu class="menu-icon-grid-dropdown">
                    <div class="menu-icon-grid">
                        <a href="#"><i class="i-Shop-4"></i> Home</a>
                        <a href="#"><i class="i-Library"></i> UI Kits</a>
                        <a href="#"><i class="i-Drop"></i> Apps</a>
                        <a href="#"><i class="i-File-Clipboard-File--Text"></i> Forms</a>
                        <a href="#"><i class="i-Checked-User"></i> Sessions</a>
                        <a href="#"><i class="i-Ambulance"></i> Support</a>
                    </div>
                </div>
            </div> -->
    
            <!-- <div ngbDropdown [placement]="'bottom-right'">
                <div class="badge-top-container">
                    <span class="badge badge-primary">{{notifications?.length}}</span>
                    <i  class="i-Bell text-muted header-icon" role="button"></i>
                </div>
            </div> -->
    
            <div ngbDropdown [placement]="'bottom-right'" class="user col align-self-end">
                <img src="/assets/images/faces/logo.png" id="userDropdown" ngbDropdownToggle alt="">
                <div ngbDropdownMenu aria-labelledby="userDropdown">
                    <button class="dropdown-item" (click)="signout()">Sign out</button>
                </div>
            </div>
    
        </div>
    
    </div>